import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { BASE_URL } from "../config";
import axios from 'axios';
import moment from 'moment';
import PieChart from '../components/pieChart';
import Confetti from '../components/confetti';
import Skeleton from 'react-loading-skeleton';
import goalImg from "../assets/images/goalImg.svg"
import stopwatchIcon from "../assets/images/stopwatchIcon.svg"
import historyIcon from "../assets/images/historyIcon.svg"
import timerIcon from "../assets/images/timerIcon.svg"
import caloriesIcon from "../assets/images/caloriesIcon.svg"
import winnerCup from "../assets/images/winnerCup.svg"
import RadialChart from '../components/radialChart';
const SummaryGoals = ({ back }) => {
    const params = useParams();
    const [caloriesBurned, setCaloriesBurned] = useState({});
    const [timeConsumed, setTimeConsumed] = useState({});
    const [timeToCompleteGoal, setTimeToCompleteGoal] = useState(0);
    const [monthlyTarget, setMonthlyTarget] = useState(0);
    const [timeToCompleteGoalPercentage, setTimeToCompleteGoalPercentage] = useState('');
    const [centerTextOne, setCenterTextOne] = useState('');
    const [centerTextTwo, setCenterTextTwo] = useState('');
    const [centerTextThree, setCenterTextThree] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [currentDay, setCurrentDay] = useState(moment().date());
    const [monthToSubtract, setMonthToSubtract] = useState(0);
    const [loading, setLoading] = useState(true); const [garConnect, setGarConnect] = useState(false);
    const [fitConnect, setFitConnect] = useState(false);
    const [gartokConnect, setGartokConnect] = useState(false);
    const [walkThroughPending, setWalkThroughPending] = useState(JSON.parse(localStorage.getItem("walkthough")).walkThroughPending);
    const [walkThroughSteps, setWalkThroughSteps] = useState(JSON.parse(localStorage.getItem("walkthough")).walkThroughSteps);
    const [count ,setCount]=useState(0);
    const navigate = useNavigate();
    useEffect(() => {

        let subscribedStatus = localStorage.getItem('subscribedStatus');

        // If subscribedStatus is falsy, redirect to membership page
        if (subscribedStatus == 'false') {
            window.location = window.location.origin + '/membership';
            return false;
        } else if (fitConnect == 'false' && garConnect == 'false') {
            navigate('/device');
            back(0);
        }
        //  else if (walkThroughPending =='true' || walkThroughSteps !== "2") {
        //     navigate('/device'); 
        //     back(0)
        // }
    });

    const convertMinsToHrsMins = (mins) => {

        if (!mins) {
            return '0 hr 0 min';
        }
        let h = Math.floor(mins / 60);
        let m = mins % 60;
        h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
        m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
        return `${h} hr ${m} min`;
    }

    const dataOne = [
        { name: 'Acheived', value: parseInt(timeConsumed?.light +timeConsumed?.vigorous), dataAvg:parseInt((timeConsumed?.light )/count|| 0), itemStyle: { color: '#239523' } },
        // { name: 'Acheived', value: parseInt(monthlyTarget - timeToCompleteGoal), itemStyle: { color: '#239523' } },
        { name: 'Remaining', value: parseInt(timeToCompleteGoal), dataAvg:parseInt((timeConsumed?.light )/count|| 0), itemStyle: { color: '#0694F3' } },
    ];
    const dataTwo = [
        { name: 'Moderate', value: parseInt(timeConsumed?.light || 0), dataAvg:parseInt((timeConsumed?.light )/count|| 0), itemStyle: { color: '#0694F3' } },
        { name: 'Vigorous', value: parseInt(timeConsumed?.moderate || 0), dataAvg:parseInt((timeConsumed?.moderate )/count|| 0), itemStyle: { color: '#FF8B20' } },
        { name: 'Peak', value: parseInt(timeConsumed?.vigorous || 0), dataAvg:parseInt((timeConsumed?.vigorous )/count|| 0), itemStyle: { color: '#F50F51' } },
        // { name: 'Moderate', value: parseInt(timeConsumed?.moderate || 0), itemStyle: { color: '#0694F3' } },
        // { name: 'Vigorous', value:parseInt(timeConsumed?.vigorous || 0), itemStyle: { color: '#FF8B20' } },
        // { name: 'Peak', value: parseInt(timeConsumed?.light || 0), itemStyle: { color: '#F50F51' } },
    ];
    const dataThree = [
        { name: 'Moderate', value: parseInt(caloriesBurned?.light || 0), dataAvg:parseInt((caloriesBurned?.light )/count|| 0),itemStyle: { color: '#0694F3' } },
        { name: 'Vigorous', value: parseInt(caloriesBurned?.moderate || 0),dataAvg:parseInt((caloriesBurned?.moderate )/count|| 0), itemStyle: { color: '#FF8B20' } },
        { name: 'Peak', value: parseInt(caloriesBurned?.vigorous || 0), dataAvg:parseInt((caloriesBurned?.vigorous )/count|| 0),itemStyle: { color: '#F50F51' } },
    ]
    const getUserActivityRecord = async (event) => {
        setLoading(true);
        let device = "FitBit";
        let url = '';
		
		//console.log("fitConnect : ", fitConnect);
        //console.log("garConnect : ", garConnect);

        const fitbitUrl = `${BASE_URL}/user/getfitbitexercisedata/${startDate}/${endDate}`;
        const garminUrl = `${BASE_URL}/user/getgarminexercisedatabydate/${startDate}/${endDate}`;

         let fitbitData = await axios({
             headers: {
                 "x-access-token": localStorage.getItem('auth-token')
             },
             url: fitbitUrl,
             method: 'GET'
         });

        let garminData = await axios({
            headers: {
                "x-access-token": localStorage.getItem('auth-token')
            },
            url: garminUrl,
            method: 'GET'
        });

        //console.log(fitbitData?.data?.data, '------*#*------', garminData?.data?.data);

        

        setLoading(false);
        let tempCaloriesBurned = {
            light: 0,
            moderate: 0,
            vigorous: 0
        };
        let tempTimeConsumed = {
            light: 0,
            moderate: 0,
            vigorous: 0
        };
		
		//if(!garConnect && fitConnect){
		if (Array.isArray(garminData?.data?.data) && !garminData.data.data.length) {
			fitbitData = fitbitData?.data?.data;
			
			let cCount = 0;
			fitbitData?.map((data) => {
				if(Math.round(data?.heartRateZones[1].minutes) > 0){
					cCount++;
				}
				tempCaloriesBurned.light = Math.round(data?.heartRateZones[1].caloriesOut) + tempCaloriesBurned.light;
				tempCaloriesBurned.moderate = Math.round(data?.heartRateZones[2].caloriesOut) + tempCaloriesBurned.moderate;
				tempCaloriesBurned.vigorous = Math.round(data?.heartRateZones[3].caloriesOut) + tempCaloriesBurned.vigorous;
				tempTimeConsumed.light = Math.round(data?.heartRateZones[1].minutes) + tempTimeConsumed.light;
				tempTimeConsumed.moderate = Math.round(data?.heartRateZones[2].minutes) + tempTimeConsumed.moderate;
				tempTimeConsumed.vigorous = Math.round(data?.heartRateZones[3].minutes) + tempTimeConsumed.vigorous;
			});
			setCount(cCount)
			
			//console.log("cCount : ", cCount);
		}
		
		setCaloriesBurned(tempCaloriesBurned);
        setTimeConsumed(tempTimeConsumed);
		
		//if((!fitConnect && garConnect) || (fitConnect && garConnect)){
		if (Array.isArray(garminData?.data?.data) && garminData.data.data.length) {
			
			tempCaloriesBurned = {
				light: 0,
				moderate: 0,
				vigorous: 0
			};
			tempTimeConsumed = {
				light: 0,
				moderate: 0,
				vigorous: 0
			};
			
			garminData = garminData?.data?.data;
			
			garminData?.map((data) => {
				let timeSpend = {};
				let calorieConsumed = {};
			   
				setCount(garminData.length)
				data?.heartRateZones?.map((data) => {
					timeSpend[data?.heartZoneName] = data?.minutes;
					calorieConsumed[data?.heartZoneName] = data?.caloriesOut;
				});
				tempCaloriesBurned.light = Math.round(calorieConsumed?.light) + tempCaloriesBurned.light;
				tempCaloriesBurned.moderate = Math.round(calorieConsumed?.moderate) + tempCaloriesBurned.moderate;
				tempCaloriesBurned.vigorous = Math.round(calorieConsumed?.vigorous) + tempCaloriesBurned.vigorous;
				tempTimeConsumed.light = Math.round(timeSpend?.light) + tempTimeConsumed.light;
				tempTimeConsumed.moderate = Math.round(timeSpend?.moderate) + tempTimeConsumed.moderate;
				tempTimeConsumed.vigorous = Math.round(timeSpend?.vigorous) + tempTimeConsumed.vigorous;
			});
		}

        setCaloriesBurned(tempCaloriesBurned);
        setTimeConsumed(tempTimeConsumed);
		
		//console.log("tempTimeConsumed : ", tempTimeConsumed);
    };

    useEffect(() => {
        getUserActivityRecord();
        const currentDateNumber = Date.now();
    }, [endDate]);

    useEffect(() => {
        // front End  Moderate = backend Light
        // front End  vigorous = backend Moderate
        // front End  light = backend vigorous
        let { vigorous, moderate, light } = timeConsumed; 
        
        vigorous = Math.round(vigorous);
        moderate = Math.round(moderate);
        light = Math.round(light);
        
        let tempMonthlyTarget = 0;
        let totalTime = light + moderate + vigorous;
        let achievedTime=vigorous+light;
        if (moderate === 0) {
            tempMonthlyTarget = 600;
        }
        if (moderate >= 1) {
            tempMonthlyTarget = 300;
        }
        setMonthlyTarget(tempMonthlyTarget);
        setTimeToCompleteGoal(Math.max(tempMonthlyTarget - achievedTime, 0));
        // setTimeToCompleteGoal(tempMonthlyTarget - totalTime);
        setTimeToCompleteGoalPercentage(parseInt((tempMonthlyTarget - achievedTime) / tempMonthlyTarget * 100));
        const timeLeft = tempMonthlyTarget - achievedTime;
        const percentage = timeLeft >= 0 ? 100 - parseInt((timeLeft / tempMonthlyTarget) * 100) :100+parseInt(Math.abs((timeLeft / tempMonthlyTarget) * 100));

        setCenterTextOne(`${percentage}% of goal \n ${convertMinsToHrsMins(timeLeft >= 0 ? parseInt(timeLeft) : 0)} \nto go`);
        // setCenterTextOne(`${100 - parseInt((tempMonthlyTarget - totalTime) / tempMonthlyTarget * 100) || 0}% of \n ${convertMinsToHrsMins(parseInt(tempMonthlyTarget - totalTime))} \nto go`);
        setCenterTextTwo(`${convertMinsToHrsMins(parseInt(timeConsumed?.moderate + timeConsumed?.vigorous + timeConsumed?.light))} \nTotal Time*`);
        setCenterTextThree(`${parseInt(caloriesBurned?.moderate + caloriesBurned?.vigorous + caloriesBurned?.light)} \nTotal Calories Burn *`);
    }, [timeConsumed]);

    const clickNextMonth = () => {
        if (parseInt(monthToSubtract) <= 0) {
            return;
        }
        setMonthToSubtract(monthToSubtract - 1);
    };

    const clickPreviousMonth = () => {
        if (parseInt(monthToSubtract) >= 2) {
            return;
        }
        setMonthToSubtract(monthToSubtract + 1);
    };

    useEffect(() => {
        let selectedStartDate = moment().subtract(monthToSubtract, 'months').startOf('month').format('YYYY-MM-DD');
        let selectedEndDate = moment().subtract(monthToSubtract, 'months').endOf('month').format('YYYY-MM-DD');
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);
    }, [monthToSubtract]);
    useEffect(() => {
        const getDeviceStatus = async () => {
            try {
                const res = await axios({
                    headers: {
                        "x-access-token": localStorage.getItem("auth-token"),
                    },
                    url: `${BASE_URL}/user/device/status`,
                    method: "GET",
                });
                setGarConnect(res.data.isGarminConnected);
                setFitConnect(res.data.isFitbitConnected);
                setGartokConnect(res.data.isGarminConnectedthroughtoken);
                
                if (!res.data.isGarminConnected && !res.data.isFitbitConnected) {

                    navigate('/device'); back(0);
                }
            } catch (err) {
                if (err.code === "ERR_BAD_REQUEST") {
                    // Handle error
                }
            }
        };

        getDeviceStatus();
        
    }, []);

    //   useEffect(() => {
    //     if (!fitConnect && !garConnect  ) {
    //         navigate('/device');back(0);
    //     } else if (walkThroughPending || walkThroughSteps !== 2) { 
    //         navigate('/device'); back(0);
    //     }
    //   }, [fitConnect, garConnect, gartokConnect, walkThroughPending, walkThroughSteps, navigate]);

    return (
        <>
            <div className="sm:p-auto summary-goal-container">
                <div className="wrapper">
                    <div className="summary-chart-main">
                        <div className="summary-chart-section">
                            <h3>
                                {moment(endDate).format('MMMM')}
                            </h3>
                            <div className="flex flex-wrap justify-center items-start md:pb-14 ">
                                <div className="">
                                    {loading ? (
                                        <Skeleton baseColor="#cccccc" highlightColor="#fff" height={'284px'} width={'300px'} className="m-2" />
                                    ) : (
                                        <RadialChart data={dataOne} centerText={centerTextOne} chartNO="1" />
                                        // <PieChart data={dataOne} centerText={centerTextOne} />
                                    )}
                                </div>
                                <div className="">
                                    {loading ? (
                                        <Skeleton baseColor="#cccccc" highlightColor="#fff" height={'284px'} width={'300px'} className="m-2" />
                                    ) : (
                                        <RadialChart data={dataTwo} centerText={centerTextTwo} chartNO="2" />
                                        // <PieChart data={dataTwo}  />
                                    )}
                                </div>
                                <div className="">
                                    {loading ? (
                                        <Skeleton baseColor="#cccccc" highlightColor="#fff" height={'284px'} width={'300px'} className="m-2" />
                                    ) : (
                                        <RadialChart data={dataThree} centerText={centerTextThree} chartNO="3" />
                                        // <PieChart data={dataTwo}  />
                                    )}
                                </div>
                            </div>
                            <div className="default-btn-outer">
                                <button disabled={loading} onClick={() => clickPreviousMonth()} type="button" style={{ zIndex: 1 }} className={`${(parseInt(monthToSubtract) >= 2) ? 'bg-gray-600 cursor-not-allowed light-default-btn' : 'cursor-pointer bg-red-btn '} default-btn prev-btn dark:focus:ring-[#E53E14]/55`}>
                                    Previous
                                </button>
                                <button disabled={loading} onClick={() => clickNextMonth()} type="button" style={{ zIndex: 1 }} className={`${(parseInt(monthToSubtract) <= 0) ? 'bg-gray-600  cursor-not-allowed light-default-btn' : 'cursor-pointer bg-red-btn '} default-btn next-btn dark:focus:ring-[#E53E14]/55`}>
                                    Next
                                </button>
                            </div>
                        </div>

                        {(timeToCompleteGoalPercentage <= 0) ? (
                            // <div className="bg-theme-blue-secondary w-[100%] md:w-[auto] mt-[300px] md:mt-[0px] h-[80vh] md:h-[100vh]">
                            //     <div style={{ position: 'relative', top: 0, bottom: 0, overflow: 'hidden' }} className="w-[500px]">
                            //         <Confetti />
                            //         <div className="flex justify-center badge-position">
                            //             <div>
                            //                 <div className="flex justify-center pt-8">
                            //                     <img style={{ height: '200px' }} src={require('../assets/award1.png')} />
                            //                 </div>
                            //                 <div className="text-3xl text-red-500 font-bold flex justify-center w-[280px]">
                            //                     {convertMinsToHrsMins(timeConsumed?.moderate + timeConsumed?.vigorous)}
                            //                 </div>
                            //                 <div className="pb-20 text-md text-gray-500 font-bold flex justify-center">
                            //                     You have acheived goal
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </div>
                            //     <div className="flex justify-center pb-20">
                            //         <div>
                            //             <div className="text-center py-6 text-xl font-bold text-gray-400 lg:text-2xl dark:text-gray-400">
                            //                 Monthly Summary
                            //             </div>
                            //             <div className="text-center pt-2 text-xl text-gray-400 dark:text-gray-400">
                            //                 Total Time (all zones) : {convertMinsToHrsMins(timeConsumed?.light + timeConsumed?.moderate + timeConsumed?.vigorous)}
                            //                 <br />
                            //                 Valid Time* : {convertMinsToHrsMins(timeConsumed?.moderate + timeConsumed?.vigorous)}
                            //                 <br />
                            //                 Avg. Valid Time Per Day : 13 h
                            //                 <br />
                            //                 Avg. Calories Spent Per Day : {parseInt((caloriesBurned?.vigorous + caloriesBurned?.moderate + caloriesBurned?.light) / 30)} Kcal
                            //             </div>
                            //         </div>
                            //     </div>
                            // </div>
                            <div className="summary-right-box winner-box">
                                <div className="summary-img-box">
                                    <img src={winnerCup} />
                                    <div className="content-head">
                                        <h4>
                                            {convertMinsToHrsMins(parseInt(timeConsumed?.moderate + timeConsumed?.vigorous + timeConsumed?.light))}
                                        </h4>
                                        <p>you have achieved goal</p>
                                    </div>
                                </div>
                                <div className="monthly-summary-box">
                                    <h5>Monthly Summary</h5>
                                    <ul>
                                        <li><span className="icon-img"><img src={stopwatchIcon} /></span> <span className="list-text">Total Valid Time <span className="time">{`${parseInt(timeConsumed?.light + timeConsumed?.moderate + timeConsumed?.vigorous)} min`}</span></span></li>
                                        <li><span className="icon-img"><img src={historyIcon}/></span> <span className="list-text"> Avg. Valid Time <span className="time">{`${parseInt((timeConsumed?.moderate + timeConsumed?.vigorous+timeConsumed?.light)/count)} min`}</span></span></li>
                                        <li><span className="icon-img"><img src={caloriesIcon} /></span> <span className="list-text">Total Valid Kcals <span className="time"> {`${parseInt(caloriesBurned?.light + caloriesBurned?.moderate + caloriesBurned?.vigorous) } Cal`}</span></span></li>
                                        <li><span className="icon-img"><img src={caloriesIcon} /></span> <span className="list-text">Avg. Valid Kcals <span className="time">{`${parseInt((caloriesBurned?.vigorous + caloriesBurned?.moderate + caloriesBurned?.light) / count)} Cal`} Kcal</span></span></li>
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            // <div className="bg-theme-blue-secondary w-[auto] md:w-[auto] mt-[300px] md:mt-[0px] h-[50vh] md:h-[100vh]">
                            //     <div className="w-[400px] md:w-[500px]">
                            //         <div >
                            //             <div>
                            //                 <div className="flex justify-center pt-8">
                            //                     <img style={{ height: '200px' }} src={'https://icon-library.com/images/exercise-icon-png/exercise-icon-png-15.jpg'} />
                            //                 </div>
                            //                 <div className="text-3xl text-red-500 font-bold flex justify-center mt-6">
                            //                     {convertMinsToHrsMins(timeToCompleteGoal)} to go
                            //                 </div>
                            //                 <div className="pb-20 text-md text-center text-gray-500 font-bold flex justify-center px-4 mt-4 w-[auto] md:w-[500px]">
                            //                     You have {convertMinsToHrsMins(timeToCompleteGoal)} left to achieve your exercise goal.
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </div>
                            //     <div className="flex justify-center pb-20">
                            //         <div>
                            //             <div className="text-center py-6 text-xl font-bold text-gray-400 lg:text-2xl dark:text-gray-400">
                            //                 Monthly Summary
                            //             </div>
                            //             <div className="text-center pt-2 text-xl text-gray-400 dark:text-gray-400">
                            //                 Total Time (all zones) : {convertMinsToHrsMins(timeConsumed?.light + timeConsumed?.moderate + timeConsumed?.vigorous)}
                            //                 <br />
                            //                 Valid Time* : {convertMinsToHrsMins(timeConsumed?.moderate + timeConsumed?.vigorous)}
                            //                 <br />
                            //                 Avg. Valid Time Per Day : {convertMinsToHrsMins(parseInt((timeConsumed?.light + timeConsumed?.moderate + timeConsumed?.vigorous) / 30))}
                            //                 <br />
                            //                 Avg. Calories Spent Per Day : {parseInt((caloriesBurned?.vigorous + caloriesBurned?.moderate + caloriesBurned?.light) / 30)} Kcal
                            //             </div>
                            //         </div>
                            //     </div>
                            // </div>
                            <div className="summary-right-box">
                                <div className="summary-img-box">
                                    <img src={goalImg} />
                                    <div className="content-head">
                                        <h4>{convertMinsToHrsMins(parseInt(timeToCompleteGoal))}. to go</h4>
                                        <p>You have {convertMinsToHrsMins(parseInt(timeToCompleteGoal))} left to achieve
                                            your exercise goal.</p>
                                    </div>
                                </div>
                                <div className="monthly-summary-box">
                                    <h5>Monthly Summary</h5>
                                    <ul>
                                        <li><span className="icon-img"><img src={stopwatchIcon} /></span> <span className="list-text">Total Valid Time <span className="time">{`${parseInt(timeConsumed?.light + timeConsumed?.moderate + timeConsumed?.vigorous)} min`}</span></span></li>
                                        <li><span className="icon-img"><img src={historyIcon}/></span> <span className="list-text"> Avg. Valid Time <span className="time">{`${parseInt((timeConsumed?.moderate + timeConsumed?.vigorous+timeConsumed?.light)/count)} min`}</span></span></li>
                                        <li><span className="icon-img"><img src={caloriesIcon} /></span> <span className="list-text">Total Valid Kcals <span className="time"> {`${parseInt(caloriesBurned?.light + caloriesBurned?.moderate + caloriesBurned?.vigorous) } Cal`}</span></span></li>
                                        <li><span className="icon-img"><img src={caloriesIcon} /></span> <span className="list-text">Avg. Valid Kcals <span className="time">{`${parseInt((caloriesBurned?.vigorous + caloriesBurned?.moderate + caloriesBurned?.light) / count)} Cal`} Kcal</span></span></li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <div className="md:w-[790px] w-[380px]">
                        <div className="bg-theme-blue md:h-[280px] h-[220px] rounded-md" >
                            <div className="text-center py-6 text-xl font-bold text-gray-400 lg:text-2xl sm:px-16 xl:px-48 dark:text-gray-400">
                                Monthly Summary
                            </div>
                            <div className="text-center pt-2 text-xl text-gray-400 sm:px-16 xl:px-48 dark:text-gray-400">
                                Total Time (all zones) : {convertMinsToHrsMins(timeToCompleteGoal)}
                                <br />
                                Valid Time* : 3 h 56 m
                                <br />
                                Avg. Valid Time Per Day : 13 h
                                <br />
                                Avg. Calories Spent Per Day : {parseInt((caloriesBurned?.vigorous + caloriesBurned?.moderate + caloriesBurned?.light) / 30)} Kcal
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}
export default SummaryGoals;
