import React, { useState } from "react";
import { RadialBarChart, RadialBar, Legend, Tooltip, Cell, PieChart, Pie } from "recharts";

const RadialChartCustom = ({ data, centerText, chartNO }) => {
  const [selectedChart, setSelectedChart] = useState(null);

  const handleLegendClick = (dataKey) => {
    setSelectedChart((prevSelected) =>
      prevSelected === dataKey ? null : dataKey
    );
  };

  const handleChartClick = (data) => {
    setSelectedChart((prevSelected) =>
      prevSelected === data.name ? null : data.name
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value, payload: itemData } = payload[0]; 
      return (
        <div
          style={{
            backgroundColor: "#333",
            padding: "8px",
            borderRadius: "5px",
            color: "#fff",
          }}
        >
          <p>{`${name}: ${value}`}{ chartNO === "3" ? ` Cals` : ` min`}</p>
          <p>Avg: {itemData.dataAvg} { chartNO === "3" ? ` Cals` : ` min`}</p> 
        </div>
      );
    }
    return null;
  };
  const CustomTooltip2 = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value, payload: itemData } = payload[0]; 
      return (
        <div
          style={{
            backgroundColor: "#333",
            padding: "8px",
            borderRadius: "5px",
            color: "#fff",
          }}
        >
          <p>{`${name}: ${value}  min `}</p>
        </div>
      );
    }
    return null;
  };
  const processedData = data.map((item) => ({
    name: item.name,
    uv: item?.value,
    fill: item.itemStyle.color,
    dataAvg: item.dataAvg,
  }));

  const legendPayload = processedData.map((item) => ({
    value: item.name,
    type: "square",
    color: item.fill,
    id: item.name,
    className: selectedChart && selectedChart !== item.name ? "line-through" : "",
  }));

  const style = {
    bottom: -20,
    left: 30,
    lineHeight: "24px",
  };

  return (
    <div>
      <PieChart width={300} height={300}>
        <Pie
          data={processedData}
          cx={150}
          cy={120}
          innerRadius={40}
          outerRadius={50}
          dataKey="uv"
        >
          {processedData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.fill}
              opacity={selectedChart && selectedChart !== entry.name ? 0.3 : 1}
            />
          ))}
        </Pie>
        <Legend
          iconSize={10}
          width={250}
          height={100}
          layout="horizontal"
          verticalAlign="bottom"
          wrapperStyle={style}
          payload={legendPayload}
          onClick={(e) => handleLegendClick(e.id)}
          formatter={(value, entry) => (
            <span
              className={
                selectedChart && selectedChart !== entry.id ? "line-through" : ""
              }
            >
              {`${value.charAt(0).toUpperCase()}${value.slice(1)}`}
            </span>
          )}
        />
       {chartNO === "1" ?<Tooltip  content={<CustomTooltip2 />}/>:<Tooltip content={<CustomTooltip />} />}
        <text
          x={150}
          y={280}
          textAnchor="middle"
          dominantBaseline="middle"
          className="center-text"
          style={{ fill: "black", fontSize: 16 }}
        >
          {chartNO === "3" ? (
            selectedChart ? (
              `${selectedChart.charAt(0).toUpperCase() + selectedChart.slice(1)} Calories : ${processedData.find((item) => item.name === selectedChart)?.uv} Calories`
            ) : (
              centerText.charAt(0).toUpperCase() + centerText.slice(1)
            )
          ) : (
            selectedChart ? (
              `${selectedChart.charAt(0).toUpperCase() + selectedChart.slice(1)} min : ${processedData.find((item) => item.name === selectedChart)?.uv} min`
            ) : (
              centerText.charAt(0).toUpperCase() + centerText.slice(1)
            )
          )}
        </text>
      </PieChart>
    </div>
  );
};

export default RadialChartCustom;